

#projects {
    text-align: center;
    padding: 10px 5%;
}

.product-list {
    text-align: center;
    padding: 40px 5%;
}

.products-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 4 per row */
    gap: 15px; /* Adjusted spacing */
    justify-content: center;
    padding: 20px 5%;
}
.p-title {
    font-size: 40px;
    margin: 0;
    color: #fde100;
    color: #fde100;
    margin-bottom: 25px;
    font-weight: bold;
    text-transform: uppercase;
}
.product {
    background-color: #222;
    color: #fff;
    border-radius: 20px; /* Rounded square */
    padding: 15px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(255, 255, 255, 0.1);
    width: 100%;
    max-width: 200px; /* Smaller item size */
    aspect-ratio: 1 / 1; /* Perfect square */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    .product:hover {
        transform: scale(1.05);
        box-shadow: 0px 6px 12px rgba(255, 255, 255, 0.2);
    }

/* IMAGE CONTAINER TO PRESERVE ASPECT RATIO */
.product-img-container {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 12px;
}

.product-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the aspect ratio is preserved */
}

.product h3 {
    margin-top: 8px;
    font-size: 1rem;
    text-align: center;
}

.product p {
    font-size: 0.8rem;
    color: #bbb;
    margin-top: 5px;
    font-style : italic;
}

/* SHOW ALL BUTTON */
.show-all-btn {
    background-color: #ff6600;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 14px;
}

    .show-all-btn:hover {
        background-color: #cc5500;
    }

/* RESPONSIVE STYLES */
@media (max-width: 1200px) {
    .products-container {
        grid-template-columns: repeat(4, 1fr); /* 3 per row on medium screens */
    }
}

@media (max-width: 900px) {
    .products-container {
        grid-template-columns: repeat(3, 1fr); /* 2 per row on smaller screens */
    }
}

@media (max-width: 600px) {
    .products-container {
        grid-template-columns: repeat(2, 1fr); /* 1 per row on mobile */
    }
}
